::selection {
  color: #fff;
  background: #ef4e8a;
}

ul {
  padding-left: 0;
}

/* .header-transparent.site-header {
    background: transparent;
} */
/* 
.pricing-box ul li.disable {
    color: #c9c9c9;
}
.pricing-box ul li.disable:before {
    filter: grayscale(1);
    opacity: 0.35;
}
*/

.accordion-style .accordion-item {
  border: none;
}
.accordion-style .accordion-button:after {
  background-image: none;
}

.accordion-style .accordion-button.collapsed {
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
}
.accordion-style .accordion-button:not(.collapsed) {
  background: linear-gradient(to right, #ef4e8a, #ffd47d);
  box-shadow: none;
  color: #fff;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}
.accordion-style .accordion-button:focus {
  box-shadow: none;
}

.faq-accordion .arrow-icon {
  position: absolute;
  height: 50px;
  right: 5px;
  width: 50px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

.faq-accordion .accordion-button.collapsed .arrow-icon:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #ef4e8a, #ffd47d);
  border-radius: 4px;
  opacity: 1;
  transition: 0.3s;
  z-index: 0;
}

.faq-accordion .arrow-icon i {
  position: relative;
  color: #ef4e8a;
  transform: rotate(-90deg);
  transition: 0.3s;
  z-index: 1;
}

.faq-accordion .accordion-button.collapsed .arrow-icon i {
  transform: rotate(0deg);
  color: #fff;
}
